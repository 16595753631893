import React from 'react';

const ButtonLoader = () => {
  return (
    <div className="center">
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
    </div>
  );
};

export default ButtonLoader;

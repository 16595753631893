import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import cross from '../assets/cross.svg';
import menu from '../assets/menu.svg';
import logo from '../assets/tamedia-with-name.png';

const Layout = () => {
  const [openSiber, setOpenSiber] = useState<boolean>(false);
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();

  const logout = async () => {
    oktaAuth.tokenManager.clear();
    history.push('/login');
  };
  return (
    <>
      <header className="page-header">
        <img className="logo" src={logo} alt="" />
        <div className="page-header__menu">
          <img src={menu} alt="menu" onClick={() => setOpenSiber(true)} />
        </div>
      </header>
      <div className={`layout ${openSiber ? 'layout--open' : ''} `}></div>
      <div className={`side-layout ${openSiber ? 'side-layout--open' : ''}`}>
        {' '}
        <div onClick={() => setOpenSiber(false)} className="side-layout--close">
          <img className="cross" src={cross} alt="close" />
        </div>
        <button onClick={logout} className="btn">
          Logout
        </button>
      </div>
    </>
  );
};

export default Layout;

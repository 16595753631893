import config from '../config';
import {
  deskNetCategoriesDev,
  deskNetCustomFieldsDev,
  deskNetElementStatusDev,
  deskNetPlatformsDev,
  deskNetPublicationStatusDev,
  deskNetTypesDev,
  elementStatusColorDev,
  printMetadataTypeDev,
  printPublicationsCategoriesDev,
  publicationFrOnlineCodeDev,
  publicationOnlineCodeDev
} from './dev';
import {
  deskNetCategoriesProd,
  deskNetCustomFieldsProd,
  deskNetElementStatusProd,
  deskNetPlatformsProd,
  deskNetPublicationStatusProd,
  deskNetTypesProd,
  elementStatusColorProd,
  printMetadataTypeProd,
  printPublicationsCategoriesProd,
  publicationFrOnlineCodeProd,
  publicationOnlineCodeProd
} from './prod';

export const printMetadataType =
  config.node_env === 'development' ? printMetadataTypeDev : printMetadataTypeProd;
export const deskNetCategories =
  config.node_env === 'development' ? deskNetCategoriesDev : deskNetCategoriesProd;
export const deskNetCustomFields =
  config.node_env === 'development' ? deskNetCustomFieldsDev : deskNetCustomFieldsProd;
export const deskNetElementStatus =
  config.node_env === 'development' ? deskNetElementStatusDev : deskNetElementStatusProd;
export const deskNetPublicationStatus =
  config.node_env === 'development' ? deskNetPublicationStatusDev : deskNetPublicationStatusProd;
export const deskNetTypes = config.node_env === 'development' ? deskNetTypesDev : deskNetTypesProd;
export const deskNetPlatforms =
  config.node_env === 'development' ? deskNetPlatformsDev : deskNetPlatformsProd;
export const elementStatusColor =
  config.node_env === 'development' ? elementStatusColorDev : elementStatusColorProd;
export const printPublicationsCategories =
  config.node_env === 'development'
    ? printPublicationsCategoriesDev
    : printPublicationsCategoriesProd;

export const publicationOnlineCode =
  config.node_env === 'development' ? publicationOnlineCodeDev : publicationOnlineCodeProd;
export const publicationFrOnlineCode =
  config.node_env === 'development' ? publicationFrOnlineCodeDev : publicationFrOnlineCodeProd;

import { CustomField } from '../../types';
export const printMetadataTypeProd: { [key: string]: string } = {
  '120_AUFM': 'primary',
  '110_AUFM_gross': 'primary',
  '120_AUFM_standard': 'primary',
  '210_AUFM_gross_MEI': 'primary',
  '220_AUFM_standard_MEI': 'primary',
  '125_Co-Text': 'secondary',
  '130_ABM': 'secondary',
  '230_ABM_MEI': 'secondary',
  '330_ABM_oLEAD_KXT': 'secondary',
  '430_ABM_KOMM_KXT': 'secondary',
  '140_DRITTST': 'news',
  '140_DRITTST / Spalte': 'news',
  '140_DRITTST_oLEAD': 'news',
  '140_DRITTST_KOL': 'news',
  '240_DRITTST_KOL_MEI': 'news',
  '340_DRITTST_oLEAD_KXT': 'news',
  '440_DRITTST_KOMM_KXT': 'news',
  '110_PRIM_large': 'primary',
  '120_PRIM_standard': 'primary',
  '130_SEC': 'secondary',
  '140_THIRD': 'news',
  '140_THIRD_COL': 'news',
  '210_PRIM_large_OPI': 'primary',
  '220_PRIM_standard_OPI': 'primary',
  '230_SEC_OPI': 'secondary',
  '240_THIRD_COL_OPI': 'news',
  '430_SEC_COM_CXT': 'secondary',
  '440_THIRD_COM_CXT': 'news'
};
export const deskNetElementStatusProd: { [key: string]: string } = {
  '1': 'Pitch',
  '2': 'Accepted',
  '3': 'Rejected',
  '4': 'No Status',
  '13281': 'Idee',
  '13291': 'Verworfen',
  '13292': 'Akzeptiert',
  '13301': 'Entwurf',
  '13302': 'Gegenlesen',
  '13312': 'Produktion',
  '13322': 'Korrektorat',
  '13331': 'Nachbearbeitung',
  '13341': 'Bereit zum Publizieren',
  '13342': 'Idée',
  '13362': 'Accepté',
  '13352': 'Rejeté',
  '13372': 'Brouillon',
  '13382': 'Relecture',
  '13392': 'Edition',
  '13402': 'Correction',
  '13412': 'Révision',
  '13422': 'Prêt à publier'
};
export const deskNetPublicationStatusProd: { [key: string]: string } = {
  '1': 'No Status',
  '28822': 'Auto-Scheduled',
  '28831': 'Nicht publiziert',
  '28841': 'A_Redaktion Print',
  '28851': 'A_Gegenlesen Print',
  '28861': 'A_Produktion Print',
  '28871': 'A_Korrektur Print',
  '28872': 'A_Nachbearbeitung Print',
  '28882': 'A_Gelesen Print',
  '28891': 'A_Fertig',
  '30212': 'Publiziert',
  '33541': 'A_Layout Print',
  '28952': 'Non publié',
  '28892': 'Programmé',
  '30222': 'Publié',
  '28902': 'A_Redaction_print',
  '28912': 'A_Relecture_print',
  '28921': 'A_Edition_print',
  '28931': 'A_Correction_print',
  '28932': 'A_Verification_print',
  '28942': 'A_Relu_print',
  '28951': 'A_Pret'
};
export const publicationOnlineCodeProd: string = '9416421';
export const publicationFrOnlineCodeProd: string = '9416441';
export const deskNetPlatformsProd: { [key: string]: string } = {
  // '9416421': 'Online DE',
  // '9416431': 'Newsletter',
  // '9418072': 'Social Media',
  '9418081': 'MANTEL',
  '9421131': 'Tages-Anzeiger',
  '9421141': 'Berner Zeitung',
  '9421151': 'Basler Zeitung',
  '9421161': 'Zürcher Regionalzeitungen',
  // '9421171': 'SonntagsZeitung',
  // '9421181': 'Finanz und Wirtschaft'
  // '9421182': 'Das Magazin',
  // '9426872': 'ZüriTipp'
  // 'Online FR': '9416441'
  '9479641': 'Journaux Romandie'
};
export const deskNetTypesProd: { [key: string]: string } = {
  '9310902': '120_AUFM',
  '9310912': '130_ABM',
  '9310922': '140_DRITTST / Spalte',
  '9310932': '125_Co-Text',
  '9311092': '120_AUFM_standard',
  '9311101': '130_ABM',
  '9311102': '140_DRITTST',
  '9311111': '125_Co-Text',
  '9311311': '120_AUFM_standard',
  '9311321': '130_ABM',
  '9311322': '140_DRITTST',
  '9311332': '125_Co-Text',
  '9311502': '120_AUFM_standard',
  '9311512': '130_ABM',
  '9311522': '140_DRITTST',
  '9311531': '125_Co-Text',
  '9311712': '120_AUFM_standard',
  '9311722': '130_ABM',
  '9311732': '140_DRITTST',
  '9311742': '125_Co-Text',
  '9311911': '120_AUFM_standard',
  '9311921': '130_ABM',
  '9311922': '140_DRITTST',
  '9311941': '125_Co-Text',
  '9326652': '110_Aufmacher',
  '9326662': '150_Abmacher',
  '9326672': '211_Kolumne',
  '9326681': '120_AUFM',
  '9326691': '130_ABM',
  '9326701': '140_DRITTST',
  '9364802': '1sp_Print',
  '9364812': '110_AUFM_gross',
  '9364822': '140_DRITTST_KOL',
  '9364832': '210_AUFM_gross_MEI',
  '9364842': '220_AUFM_standard_MEI',
  '9364852': '230_ABM_MEI',
  '9364862': '240_DRITTST_KOL_MEI',
  '9364872': '430_ABM_KOMM_KXT',
  '9364882': '440_DRITTST_KOMM_KXT',
  '9364992': '1sp_Print',
  '9365002': '1sp_Print',
  '9365011': '110_AUFM_gross',
  '9365021': '140_DRITTST_KOL',
  '9365031': '210_AUFM_gross_MEI',
  '9365041': '220_AUFM_standard_MEI',
  '9365051': '230_ABM_MEI',
  '9365061': '240_DRITTST_KOL_MEI',
  '9365071': '430_ABM_KOMM_KXT',
  '9365081': '440_DRITTST_KOMM_KXT',
  '9365082': '110_AUFM_gross',
  '9365092': '140_DRITTST_KOL',
  '9365102': '210_AUFM_gross_MEI',
  '9365112': '220_AUFM_standard_MEI',
  '9365122': '230_ABM_MEI',
  '9365132': '240_DRITTST_KOL_MEI',
  '9365142': '430_ABM_KOMM_KXT',
  '9365152': '440_DRITTST_KOMM_KXT',
  '9365262': '1sp_Print',
  '9365272': '110_AUFM_gross',
  '9365282': '140_DRITTST_KOL',
  '9365292': '210_AUFM_gross_MEI',
  '9365302': '220_AUFM_standard_MEI',
  '9365312': '230_ABM_MEI',
  '9365322': '240_DRITTST_KOL_MEI',
  '9365332': '430_ABM_KOMM_KXT',
  '9365342': '440_DRITTST_KOMM_KXT',
  '9365351': '1sp_Print',
  '9365361': '110_AUFM_gross',
  '9365371': '140_DRITTST_KOL',
  '9365381': '210_AUFM_gross_MEI',
  '9365391': '220_AUFM_standard_MEI',
  '9365401': '230_ABM_MEI',
  '9365411': '240_DRITTST_KOL_MEI',
  '9365421': '430_ABM_KOMM_KXT',
  '9365431': '440_DRITTST_KOMM_KXT',
  '9374962': '110_PRIM_large',
  '9374972': '120_PRIM_standard',
  '9374982': '130_SEC',
  '9375602': '140_THIRD',
  '9375612': '1col_Print',
  '9375622': '140_THIRD_COL',
  '9375632': '210_PRIM_large_OPI',
  '9375642': '230_SEC_OPI',
  '9375652': '240_THIRD_COL_OPI',
  '9375662': '430_SEC_COM_CXT',
  '9375672': '440_THIRD_COM_CXT'
};
export const deskNetCategoriesProd: { [key: string]: string } = {
  '10304531': 'Instagram',
  '10304541': 'Facebook',
  '10304551': 'Twitter',
  '10304571': 'Wirtschaft',
  '10304581': 'Wirtschaft-Gold',
  '10304591': 'Wirtschaft-Silber',
  '10304601': 'Wirtschaft-Bronze',
  '10304611': 'Schweiz',
  '10304621': 'Schweiz-Gold',
  '10304631': 'Schweiz-Silber',
  '10304641': 'Schweiz-Bronze',
  '10304651': 'International',
  '10304661': 'M_International-Gold',
  '10304671': 'M_International-Silber',
  '10304681': 'M_International-Bronze',
  '10826302': 'M_International-Kupfer',
  '10826312': 'M_International-Eisen',
  '10318151': 'M_Kehrseite',
  '10318161': 'M_Hintergrund',
  '10318162': 'Kultur & Gesellschaft',
  '10318171': 'Front',
  '10318181': 'Seite Zwei',
  '10318191': 'Debatte',
  '10318192': 'Zürich',
  '10318212': 'Front',
  '10318222': 'Bern-BZST+BZOA+BZEM',
  '10318232': 'Emmental-BZEM',
  '10318241': 'Meinungen',
  '10318251': 'Oberaargau-BZOA+BZEM',
  '10318261': 'Magazin-BZST+BZOA+BZEM',
  '10318271': 'Finale-BUND',
  '10318272': 'Region-BZST',
  '10318282': 'Fotoseite-BUND',
  '10318292': 'Ausgehen-BZST+BZOA+BZEM',
  '10318302': 'Front',
  '10318321': 'Fasnacht',
  '10318332': 'Meinungen und Profile',
  '10318341': 'Region',
  '10318361': 'Region Kultur',
  '10318362': 'Region Thema',
  '10318371': 'Region Wirtschaft',
  '10318381': 'Regionalsport',
  '10318402': 'Front-ZRZ',
  '10318412': 'Winterthur',
  '10318422': 'Region',
  '10318431': 'Forum-ZRZ',
  '10318441': 'Zürich',
  '10318451': 'Regionalsport',
  '10318461': 'Plakat',
  '10318531': 'Front',
  '10318532': 'Nachrichten',
  '10318541': 'Fokus',
  '10318542': 'Sport',
  '10318552': 'Wirtschaft',
  '10318571': 'Wissen',
  '10318581': 'Reisen',
  '10318582': 'Leben & Kultur',
  '10461621': 'Kultur & Gesellschaft-National',
  '10461631': 'Kultur & Gesellschaft-02',
  '10461641': 'Kultur & Gesellschaft-03',
  '10461651': 'Kultur & Gesellschaft-04',
  '10461661': 'Kultur & Gesellschaft-05',
  '10461671': 'Kultur & Gesellschaft-06',
  '10461681': 'M_Seite3',
  '10461691': 'Événement',
  '10461701': 'Événement-01-02',
  '10461711': 'Événement-03-04',
  '10461761': 'M_Sport_CH',
  '10461771': 'M_Sport_CH-01',
  '10461781': 'M_Sport_CH-02',
  '10461791': 'M_Sport_CH-03',
  '10461801': 'M_Sport_CH-04',
  '10461811': 'M_Sport_CH-05',
  '10461821': 'M_Sport_ZH',
  '10461831': 'M_Sport_ZH-01',
  '10461841': 'M_Sport_ZH-02',
  '10461851': 'M_Sport_ZH-03',
  '10461861': 'M_Sport_ZH-04',
  '10461871': 'M_Sport_BE',
  '10461881': 'M_Sport_BE-BEZ-01',
  '10461891': 'M_Sport_BE-BEZ-02',
  '10461901': 'M_Sport_BE-BEZ-03',
  '10461911': 'M_Sport_BE-BEZ-04',
  '10461921': 'M_Sport_BE-BEZ-05',
  '10461931': 'M_Sport_BE-BEZ-06',
  '10461941': 'M_Sport_BE-BEZ-07',
  '10461951': 'M_Sport_BE-BUN-01',
  '10461961': 'M_Sport_BE-BUN-02',
  '10461971': 'M_Sport_BE-BUN-03',
  '10461981': 'M_Sport_BE-BUN-04',
  '10461991': 'M_Sport_BE-BUN-05',
  '10462001': 'M_Sport_BE-BUN-06',
  '10462011': 'Wissen',
  '10462021': 'M_Wochenende',
  '10462022': 'Zürich-EVE',
  '10462032': 'Zürich-01',
  '10462042': 'Zürich-02',
  '10462052': 'Zürich-03',
  '10462062': 'Zürich-04',
  '10462071': 'Forum',
  '10462081': 'Samstagsstrecke-BUND',
  '10462091': 'Regionalsport',
  '10462101': 'Regionalsport-BZST+BZOA+BZEM',
  '10462111': 'Regionalsport-BZEM',
  '10462121': 'Regionalsport-BZOA',
  '10462131': 'Regionalsport-BZBO+BZTT',
  '10462141': 'TT',
  '10462151': 'Region-01-BZBO+BZTT',
  '10462161': 'Region-02-BZBO+BZTT',
  '10462171': 'Front-BZST',
  '10462181': 'Front-BUND',
  '10462191': 'Front-BZBO',
  '10462201': 'Front-BZTT',
  '10462211': 'Bern-01-02-unecht-BZST+BZOA+BZEM',
  '10462221': 'Bern-01-BZST+BZOA+BZEM',
  '10462231': 'Bern-02-BZST+BZOA+BZEM',
  '10462241': 'Bern-03-BZST+BZOA+BZEM',
  '10462251': 'Bern-04-BZST+BZOA+BZEM',
  '10462261': 'Bern-05-BZST+BZOA+BZEM',
  '10462271': 'Oberaargau-01-BZOA+BZEM',
  '10462281': 'Oberaargau-02-BZOA+BZEM',
  '10462291': 'Oberaargau-03-BZOA+BZEM',
  '10462301': 'Magazin-01-BZST+BZOA+BZEM',
  '10462311': 'Magazin-02-BZST+BZOA+BZEM',
  '10462321': 'Magazin-03-BZST+BZOA+BZEM',
  '10462331': 'Region-01-BZST',
  '10462341': 'Region-02-BZST',
  '10462351': 'Region-03-BZST',
  '10462361': 'Region-04-BZST',
  '10462371': 'Ausgehen-01-BZST+BZOA+BZEM',
  '10462381': 'Ausgehen-02-BZST+BZOA+BZEM',
  '10462382': 'Region_Auftakt-BAZ',
  '10462392': 'Region-01-BAZ',
  '10462402': 'Region-02-BAZ',
  '10462412': 'Region-03-BAZ',
  '10462422': 'Region-04-BAZ',
  '10462432': 'Region-05-BAZ',
  '10462442': 'Region Kultur-01',
  '10462452': 'Region Kultur-02',
  '10462462': 'Region Kultur-03',
  '10462472': 'Regionalsport-01-BAZ',
  '10462482': 'Regionalsport-02-BAZ',
  '10462492': 'Regionalsport-03-BAZ',
  '10462502': 'Regionalsport-04-BAZ',
  '10462512': 'Regionalsport-05-BAZ',
  '10462522': 'Regionalsport-06-BAZ',
  '10462571': 'Front-LAB',
  '10462581': 'Front-ZSZ',
  '10462591': 'Front-ZUM',
  '10462601': 'Winterthur-01-LAB',
  '10462611': 'Winterthur-02-LAB',
  '10462621': 'Winterthur-03-LAB',
  '10462631': 'Winterthur-04-LAB',
  '10462641': 'Region-LAB',
  '10462651': 'Region-01-LAB',
  '10462661': 'Region-02-LAB',
  '10462671': 'Region-03-LAB',
  '10462681': 'Region-04-LAB',
  '10462691': 'Region-ZSZ',
  '10462701': 'Region-01-ZSZ',
  '10462711': 'Region-02-ZSZ',
  '10462721': 'Region-03-ZSZ',
  '10462731': 'Region-04-ZSZ',
  '10462741': 'Region-ZUM',
  '10462751': 'Region-01-ZUM',
  '10462761': 'Region-02-ZUM',
  '10462771': 'Region-03-ZUM',
  '10462781': 'Region-04-ZUM',
  '10462791': 'Forum-LAB',
  '10462801': 'Forum-ZSZ',
  '10462811': 'Zürich-01',
  '10462821': 'Zürich-02',
  '10462831': 'Zürich-03',
  '10462841': 'Regionalsport-ZRZ',
  '10462851': 'Regionalsport-LAB',
  '10462861': 'Regionalsport-ZSZ',
  '10462871': 'Plakat-LAB',
  '10462881': 'Plakat-ZSZ',
  '10462891': 'Plakat-ZUM',
  '10462932': 'Front',
  '10462942': 'Binswanger',
  '10462952': 'Diverses',
  '10462962': 'Editorial',
  '10462972': 'Geschichte',
  '10462982': 'Gespräch',
  '10462992': 'Rätsel',
  '10463002': 'Tag im Leben',
  '10463011': 'Cover',
  '10463021': 'Editorial',
  '10463031': 'Grosse Geschichte',
  '10463041': 'Kopf der Woche',
  '10463051': 'Kino / Magnet',
  '10463061': 'Stil & Shopping',
  '10463071': 'Körper & Karma',
  '10463081': 'Musik',
  '10463091': 'Bühne',
  '10463101': 'Kunstmagnet',
  '10463111': 'Gastro',
  '10463121': 'Familienbande',
  '10463131': 'Fotokolumne',
  '10463141': 'Kolumne',
  '10463151': 'Agenda',
  '10463161': 'Bilder',
  '10463171': 'Die Karte',
  '10463181': 'Die Liste',
  '10463191': 'Inhalt',
  '10463201': 'Frontseite',
  '10463211': 'Meinung',
  '10463221': 'Meinung-Seite 2',
  '10463231': 'Meinung-Seite 3',
  '10463241': 'Unternehmen',
  '10463251': 'Unternehmen-Seite 5',
  '10463261': 'Unternehmen-Seite 6',
  '10463271': 'Unternehmen-Seite 7',
  '10463281': 'Unternehmen-Seite 8',
  '10463291': 'Unternehmen-Seite 9',
  '10463301': 'Unternehmen-Seite 10',
  '10463311': 'Unternehmen-Seite 11',
  '10463321': 'Unternehmen-Seite 12',
  '10463331': 'Unternehmen-Seite 13',
  '10463341': 'Unternehmen-Seite 14',
  '10463351': 'Unternehmen-Seite 15',
  '10463361': 'Unternehmen-Seite 16',
  '10463371': 'Unternehmen-Seite 17',
  '10463381': 'Unternehmen-Seite 18',
  '10463391': 'Unternehmen-Seite 19',
  '10463401': 'Märkte',
  '10463411': 'Märkte-Seite 15',
  '10463421': 'Märkte-Seite 16',
  '10463431': 'Märkte-Seite 17',
  '10463441': 'Märkte-Seite 18',
  '10463451': 'Märkte-Seite 19',
  '10463461': 'Märkte-Seite 20',
  '10463471': 'Märkte-Seite 21',
  '10463481': 'Märkte-Seite 22',
  '10463491': 'Märkte-Seite 23',
  '10463501': 'Märkte-Seite 24',
  '10463511': 'Märkte-Seite 25',
  '10463521': 'Märkte-Seite 26',
  '10463531': 'Märkte-Seite 27',
  '10483222': 'News-Briefing',
  '10483282': 'Sport',
  '10483292': 'Switzerland',
  '10483312': 'International',
  '10483331': 'Economy',
  '10483341': 'Blogs FuW',
  '10483352': 'Companies',
  '10483371': 'Investments',
  '10483382': 'Lifestyle',
  '10483401': 'Markets',
  '10483421': 'Morning Report',
  '10483432': 'Opinions (FuW)',
  '10485031': 'Zueritipp',
  '10491072': 'Basel',
  '10491092': 'FC Basel',
  '10492371': 'Regionalsport-ZUM',
  '10492462': 'Region-03-BZBO+BZTT',
  '10492472': 'Region-04-BZBO+BZTT',
  '10492482': 'Region-05-BZBO+BZTT',
  '10492492': 'BO',
  '10492502': 'BO-01',
  '10492512': 'BO-02',
  '10492522': 'BO-03',
  '10492532': 'BO-04',
  '10492542': 'BO-05',
  '10492852': 'Das Magazin',
  '10493181': 'Elections',
  '10493192': 'Good News',
  '10493212': 'Winterthur',
  '10493222': 'Dialogplatz',
  '10493252': 'Apropos-Podcast',
  '10493262': 'Los Emol',
  '10493272': 'BaZ direkt',
  '10493301': 'Regional Sports',
  '10493321': 'Gastro ZRZ',
  '10493332': 'Canton',
  '10493352': 'Start-ups & Innovation',
  '10493372': 'Flughafen Zürich',
  '10493391': 'Horgen',
  '10493402': 'Meilen',
  '10493422': 'Zurich',
  '10493472': 'Opinion',
  '10493482': 'Culture & Entertainment',
  '10493501': 'Science',
  '10493512': 'Digital',
  '10493531': 'Panorama',
  '10493551': 'Cars',
  '10493562': 'Video',
  '10493581': 'Blog',
  '10493592': 'Sunday Paper',
  '10493611': 'About us',
  '10493631': 'Trending topics',
  '10493642': 'Gesprächsstoff',
  '10493662': 'Bern Mittelland',
  '10493681': 'Emmental',
  '10493692': 'Oberaargau',
  '10493712': 'Berner Oberland',
  '10493731': 'Thun',
  '10493742': 'Biel/Seeland/Berner Jura',
  '10493752': 'Kanton Bern',
  '10493771': 'BSC Young Boys',
  '10493781': 'Gastronomie',
  '10493791': 'Bern',
  '10493802': 'Alles klar, Amerika?',
  '10493822': 'Dritte Halbzeit',
  '10493841': 'Eisbrecher',
  '10493851': 'Politbüro',
  '10493862': 'Tages-Anzeigerin',
  '10494042': 'Vaud',
  '10494061': 'Family',
  '10494072': 'Health',
  '10494091': 'Savoir',
  '10494111': 'Environment',
  '10494122': 'Geneva',
  '10494161': 'Signé Genève',
  '10504881': 'Travel',
  '10504992': 'TT-06',
  '10505002': 'BO-06',
  '10505012': 'TT-Thun & Region',
  '10505022': 'TT-Stadt Thun',
  '10505032': 'TT-Region Thun',
  '10505042': 'BO-Oberland',
  '10505052': 'BO-Oberland Ost',
  '10505062': 'BO-Oberland West',
  '10505072': 'BO-BO/TT-Kanton',
  '10505491': 'Mittlere Geschichte',
  '10506761': 'Nachrichten-2/3',
  '10506771': 'Nachrichten-Inland',
  '10506781': 'Nachrichten-Ausland',
  '10506791': 'Zürich-Gold',
  '10506801': 'Zürich-Silber',
  '10506811': 'Zürich-Bronze',
  '10507591': 'Youth Channel',
  '10508552': 'Local Sports',
  '10508612': 'Pfeffer',
  '10508622': '65 plus',
  '10508642': 'Region-ZSZ-Dorfleben',
  '10508652': 'Samstag extra-ZUM',
  '10508662': 'Region-ZUM-Dorfleben',
  '10509232': 'Region-ZSZ-Einspalter',
  '10509242': 'Region-ZUM-Einspalter',
  '10509272': 'Law & Consumption',
  '10509362': 'BZ am Abend',
  '10510321': 'Service',
  '10511011': 'Wirtschaft-Geld&Recht',
  '10511661': 'Leben & Kultur-01',
  '10511671': 'Leben & Kultur-02',
  '10511681': 'Leben & Kultur-03',
  '10511691': 'Leben & Kultur-04',
  '10511701': 'Leben & Kultur-05',
  '10511711': 'Leben & Kultur-06',
  '10515901': 'Events',
  '10515921': 'Own matter',
  '10517552': 'Leben & Kultur-07',
  '10593702': 'Der Morgen',
  '10593711': 'ZSZ am Morgen',
  '10593721': 'ZU am Morgen',
  '10593731': 'Der Landbote am Morgen',
  '10713521': 'Region-06-BAZ',
  '10713531': 'Region-07-BAZ',
  '10713541': 'Region-08-BAZ',
  '10713551': 'Region-09-BAZ',
  '10713561': 'Region-10-BAZ',
  '10713571': 'Region-11-BAZ',
  '10713581': 'Region-12-BAZ',
  '10713591': 'Region-13-BAZ',
  '10713601': 'Region-14-BAZ',
  '10729471': 'M_Kultur',
  '10729481': 'M_Kultur-Gold',
  '10729491': 'M_Kultur-Silber',
  '10729501': 'M_Kultur-Bronze',
  '10729511': 'M_Kultur-Kupfer',
  '10729521': 'M_Kultur-Eisen',
  '10729531': 'M_Politik',
  '10729541': 'M_Politik-Gold',
  '10729551': 'M_Politik-Silber',
  '10729561': 'M_Politik-Bronze',
  '10729571': 'M_Politik-Kupfer',
  '10729581': 'M_Politik-Eisen',
  '10731271': 'Zürich-Kupfer',
  '10731482': 'Region',
  '10742951': 'Unter Verdacht',
  '10782411': 'M_Geld',
  '11281362': 'Une-JRO',
  '11281372': 'Une-24H',
  '11281382': 'Une-TDG',
  '11281392': 'Opinions-JRO',
  '11281402': 'Opinions-24H',
  '11281412': 'Opinions-TDG',
  '11281422': 'Point fort',
  '11281432': 'Vaud-24H',
  '11281442': 'Vaud-01-24H',
  '11281452': 'Vaud-02-24H',
  '11281462': 'Vaud-03-24H',
  '11281472': 'Evenement-TDG',
  '11281482': 'Genève-TDG',
  '11281492': 'Genève-01-TDG',
  '11281502': 'Genève-02-TDG',
  '11281512': 'Genève-03-TDG',
  '11281522': 'M_Actu-JRO',
  '11281532': 'M_Actu-01-24H',
  '11281542': 'M_Actu-02-24H',
  '11281552': 'M_Actu-03-24H',
  '11281562': 'M_Actu-04-24H',
  '11281572': 'M_Actu-05-24H',
  '11315192': 'M_Sports_CH-JRO',
  '11315202': 'M_Sports_CH-01-24H',
  '11315212': 'M_Sports_CH-02-24H',
  '11315222': 'M_Sports_CH-03-24H',
  '11315232': 'Sports_VD-JRO',
  '11315242': 'Sports_VD-01-24H',
  '11315252': 'Sports_VD-02-24H',
  '11315262': 'Sports_VD-03-24H',
  '11315272': 'Sports_GE-JRO',
  '11315282': 'Sports_GE-01-TDG',
  '11315292': 'Sports_GE-02-TDG',
  '11315302': 'Sports_GE-03-TDG',
  '11315312': 'M_Culture-JRO',
  '11315322': 'M_Culture-01-24H',
  '11315332': 'M_Culture-02-24H',
  '11315342': 'M_Culture-03-24H'
};
export const deskNetCustomFieldsProd: CustomField[] = [
  {
    id: 6952,
    active: true,
    type: 2,
    name: 'Vorlage',
    options: [
      {
        id: 20642,
        name: 'Standard'
      },
      {
        id: 20652,
        name: 'Listicle'
      },
      {
        id: 20662,
        name: 'Interview'
      },
      {
        id: 20672,
        name: 'Meinung'
      },
      {
        id: 22011,
        name: 'Faktencheck'
      },
      {
        id: 22021,
        name: 'Einzelkritiken'
      },
      {
        id: 22031,
        name: 'Leer'
      },
      {
        id: 44251,
        name: 'Liveticker'
      }
    ]
  },
  {
    id: 6961,
    active: false,
    type: 3,
    name: 'Tenants',
    options: [
      {
        id: 20691,
        name: 'BO'
      },
      {
        id: 20701,
        name: 'BZ'
      },
      {
        id: 20711,
        name: 'BaZ'
      },
      {
        id: 20721,
        name: 'Bund'
      },
      {
        id: 20731,
        name: 'LB'
      },
      {
        id: 20741,
        name: 'LT'
      },
      {
        id: 20751,
        name: 'TA'
      },
      {
        id: 20761,
        name: 'TT'
      },
      {
        id: 20771,
        name: 'ZSZ'
      },
      {
        id: 20781,
        name: 'ZU'
      }
    ]
  },
  {
    id: 6971,
    active: true,
    type: 2,
    name: 'Abo / Paywall',
    options: [
      {
        id: 20791,
        name: 'Nein'
      },
      {
        id: 20801,
        name: 'Ja'
      }
    ]
  },
  {
    id: 6981,
    active: true,
    type: 2,
    name: '#Platzierung erledigt?',
    options: [
      {
        id: 20811,
        name: 'offen'
      },
      {
        id: 20821,
        name: 'erledigt'
      },
      {
        id: 23712,
        name: 'T11 platziert'
      },
      {
        id: 23722,
        name: 'A platziert'
      }
    ]
  },
  {
    id: 6991,
    active: true,
    type: 1,
    name: '#Link zu Original W-CH'
  },
  {
    id: 7001,
    active: true,
    type: 1,
    name: '#Push-Vorschlag (126)'
  },
  {
    id: 7941,
    active: true,
    type: 2,
    name: '-----',
    options: [
      {
        id: 24461,
        name: '-----'
      },
      {
        id: 52851,
        name: 'Schube'
      }
    ]
  },
  {
    id: 8111,
    active: true,
    type: 2,
    name: '#Vorschlag Platzierung',
    options: [
      {
        id: 25071,
        name: 'A-Story'
      },
      {
        id: 25081,
        name: 'Mögliche A-Story'
      },
      {
        id: 25091,
        name: 'Für T11 geeignet'
      },
      {
        id: 25101,
        name: 'Repost'
      },
      {
        id: 59672,
        name: 'Primeur'
      }
    ]
  },
  {
    id: 9522,
    active: true,
    type: 2,
    name: 'Haupt-Mandant',
    options: [
      {
        id: 29852,
        name: 'Tages-Anzeiger'
      },
      {
        id: 29862,
        name: 'Der Landbote'
      },
      {
        id: 29872,
        name: 'Zürcher Unterländer'
      },
      {
        id: 29882,
        name: 'Zürichsee-Zeitung'
      },
      {
        id: 29892,
        name: 'Berner Zeitung'
      },
      {
        id: 29902,
        name: 'Berner Oberländer'
      },
      {
        id: 29912,
        name: 'Langenthaler Tagblatt'
      },
      {
        id: 29922,
        name: 'Thuner Tagblatt'
      },
      {
        id: 29932,
        name: 'Der Bund'
      },
      {
        id: 29942,
        name: 'Basler Zeitung'
      },
      {
        id: 29952,
        name: '12-App'
      },
      {
        id: 29962,
        name: 'Finanz und Wirtschaft'
      }
    ]
  },
  {
    id: 9551,
    active: false,
    type: 2,
    name: '#Priorität Print',
    options: [
      {
        id: 30051,
        name: '1'
      },
      {
        id: 30061,
        name: '2'
      },
      {
        id: 30071,
        name: '3'
      }
    ]
  },
  {
    id: 10631,
    active: true,
    type: 2,
    name: '#Print Priorität',
    options: [
      {
        id: 39621,
        name: '1'
      },
      {
        id: 39631,
        name: '2'
      },
      {
        id: 39641,
        name: '3'
      },
      {
        id: 45081,
        name: 'Print offen'
      }
    ]
  },
  {
    id: 16741,
    active: true,
    type: 2,
    name: 'Textlänge',
    options: [
      {
        id: 71191,
        name: 'S - 2000'
      },
      {
        id: 71201,
        name: 'M - 4000'
      },
      {
        id: 71211,
        name: 'L - 6000'
      },
      {
        id: 71221,
        name: 'XL - 8000'
      },
      {
        id: 71231,
        name: 'XXL - 15000'
      },
      {
        id: 104702,
        name: 'XXXL - 35000'
      }
    ]
  }
];
export const elementStatusColorProd: { [key: string]: string } = {
  Idee: '#ffb2dc',
  Akzeptiert: '#82e580',
  Entwurf: '#fee900',
  Gegenlesen: '#ffb346',
  Produktion: '#01a5f0',
  Korrektorat: '#b56eef',
  Nachbearbeitung: '#ff5f62',
  'Bereit zum Publizieren': '#47994d',
  Verworfen: '#081d42',
  Idée: '#ffb2dc',
  Accepté: '#82e580',
  Brouillon: '#fee900',
  Relecture: '#ffb346',
  Edition: '#01a5f0',
  Correction: '#b56eef',
  Révision: '#ff5f62',
  'Prêt à publier': '#47994d',
  Rejeté: '#081d42'
  // ## not used:
  // 'Pitch' : "#01a5f0",
  // 'Accepted' : "#82e580",
  // 'Rejected' : "#ff5f62",
  // 'No Status' : "#FFFFFF",
};
export const printPublicationsCategoriesProd: { [key: string]: string[] } = {
  MANTEL: [
    'Börse',
    'Geld & Recht',
    'Hintergrund',
    'International',
    'Kehrseite',
    'Kultur, Gesellschaft & Wissen',
    'Mobil',
    'Politik & Wirtschaft',
    'Seite Drei',
    'Sport_BE',
    'Sport_CH',
    'Sport_ZH',
    'Wochenende',
    'MAN_Zürich',
    'Rätsel',
    'Anzeige'
  ],
  'Tages-Anzeiger': [
    'Agenda',
    'Diverses',
    'Forum',
    'Front',
    'Kooperation',
    'Meinungen',
    'Spezial',
    'TV & Radio',
    'Wetter',
    'Zürich',
    'Leserforum',
    'Alpha',
    'Börse',
    'Geld & Recht',
    'Hintergrund',
    'International',
    'Kehrseite',
    'Kultur, Gesellschaft & Wissen',
    'Mobil',
    'Politik & Wirtschaft',
    'Seite Drei',
    'Sport_BE',
    'Sport_CH',
    'Sport_ZH',
    'Wochenende',
    'MAN_Zürich',
    'Rätsel',
    'Anzeige'
  ],
  'Berner Zeitung': [
    '65 plus',
    'Agenda',
    'Diverses',
    'Emmental',
    'Finale',
    'Forum',
    'Fotografie',
    'Front',
    'Hintergrund Bund',
    'Kanton Bern',
    'Kino',
    'Magazin',
    'Meinungen',
    'Oberaargau',
    'Pfeffer',
    'Region',
    'Region Bern',
    'Regionalsport',
    'Samstagsinterview',
    'Stadt Bern',
    'Thema',
    'TV & Radio',
    'TV Woche',
    'Unterhaltung',
    'Wetter',
    'Börse',
    'Geld & Recht',
    'Hintergrund',
    'International',
    'Kehrseite',
    'Kultur, Gesellschaft & Wissen',
    'Mobil',
    'Politik & Wirtschaft',
    'Seite Drei',
    'Sport_BE',
    'Sport_CH',
    'Sport_ZH',
    'Wochenende',
    'MAN_Zürich',
    'Rätsel',
    'Anzeige'
  ],
  'Basler Zeitung': [
    'Denksport',
    'Diverses',
    'Fasnacht',
    'Fernsehen Tipps',
    'Front',
    'Kino',
    'Meinungen und Profile',
    'Region',
    'Region Agenda',
    'Region Thema',
    'Regionalsport',
    'Wetter',
    'Börse',
    'Geld & Recht',
    'Hintergrund',
    'International',
    'Kehrseite',
    'Kultur, Gesellschaft & Wissen',
    'Mobil',
    'Politik & Wirtschaft',
    'Seite Drei',
    'Sport_BE',
    'Sport_CH',
    'Sport_ZH',
    'Wochenende',
    'MAN_Zürich',
    'Rätsel',
    'Anzeige'
  ],
  'Zürcher Regionalzeitungen': [
    'Agenda',
    'Dorfleben',
    'Forum',
    'Front',
    'Horgen',
    'Leserbriefe',
    'Marktplatz',
    'Meilen',
    'Region',
    'Regionalsport',
    'Samstag extra',
    'Schaufenster',
    'Treffpunkt',
    'TV / Radio',
    'Wetter',
    'Winterthur',
    'Zürich',
    'Börse',
    'Geld & Recht',
    'Hintergrund',
    'International',
    'Kehrseite',
    'Kultur, Gesellschaft & Wissen',
    'Mobil',
    'Politik & Wirtschaft',
    'Seite Drei',
    'Sport_BE',
    'Sport_CH',
    'Sport_ZH',
    'Wochenende',
    'MAN_Zürich',
    'Rätsel',
    'Anzeige'
  ],
  SonntagsZeitung: [
    'Auto',
    'Extra',
    'Fokus',
    'Front',
    'Gesellschaft',
    'Kultur',
    'Leben & Kultur',
    'Nachrichten',
    'Reisen',
    'Sport',
    'Wirtschaft',
    'Wissen',
    'ALPHA',
    'Anzeige'
  ],
  'Finanz und Wirtschaft': [
    'Frontseite',
    'Import',
    'Märkte',
    'Meinung',
    'Monitor',
    'Unternehmen',
    'Anzeige'
  ],
  'Journaux Romandie': [
    'Une',
    'Actu',
    'Arc lémanique',
    'Genève',
    'Opinions',
    'Point fort',
    'Sports_CH',
    'Sports_GE',
    'Sports_VD',
    'Vaud',
    'Événement',
    'Agenda',
    'Cinéma',
    'Culture',
    'Jeux/Météo',
    'Sortir ce week-end',
    'Auto',
    'Bons plans',
    'Emploi',
    'Gastronomie',
    'Immobilier',
    'Signé Genève',
    'Dossier',
    'Magazine',
    'Mortuaires',
    'Pub'
  ]
};

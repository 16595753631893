import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { Route, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from './config';
import './fontawesome';
import Home from './pages/Home';
import Layouts from './pages/Layouts';
import Login from './pages/Login';
import './styles/index.scss';

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ToastContainer />
      <SecureRoute exact path="/" component={Home} />
      <SecureRoute exact path="/layouts" component={Layouts} />
      <Route path="/login" exact={true} component={Login} />
      <Route path="/openid/callback" component={LoginCallback} />
    </Security>
  );
}

export default App;

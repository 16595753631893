import ButtonLoader from './ButtonLoader';

type ButtonProps = {
  onClick: () => void;
  text: string;
  loading?: boolean;
  disabled?: boolean;
};

const Button = ({ text, disabled, loading = false, onClick }: ButtonProps) => {
  return (
    <div className="button-wrapper">
      <button
        className={`btn ${loading ? 'btn-loading' : ''}`}
        disabled={loading || disabled}
        onClick={onClick}
      >
        {text} {loading && <ButtonLoader />}
      </button>
    </div>
  );
};
export default Button;

import { useRef, useState } from 'react';
import useOnClickOutside from '../hooks/useOnClickOutside';

import { faEraser, faPenNib, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function MultiDropdown({
  placeholdar,
  options,
  selectedValues,
  handleAddItem,
  handleRemoveItem,
  handleRemoveAll
}: {
  options: {
    id?: number;
    value: string;
    label: string;
  }[];
  selectedValues: {
    id?: number;
    value: string;
    label: string;
  }[];
  placeholdar?: string;
  handleAddItem: (val: object) => void;
  handleRemoveItem: (val: object) => void;
  handleRemoveAll?: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useOnClickOutside(ref, () => setIsOpen(false));

  const selectOption = (option: any) => {
    handleAddItem(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div ref={ref} className="dropdown">
        <div
          className={`dropdown__header ${isOpen && 'dropdown__header--open'}`}
          onClick={toggleDropdown}
        >
          <FontAwesomeIcon icon={faPenNib} />
          <p className="dropdown__value">{placeholdar || 'Select...'}</p>
          <i className={`arrow ${isOpen ? 'up' : 'down'}`} />
        </div>

        <ul className={`dropdown__options ${isOpen && 'dropdown__options--open'}`}>
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => selectOption(option)}
              className={`${selectedValues.some((obj) => obj.value === option.value) ? 'disabled' : ''}`}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
      <div className="multi-dropdown-values">
        {selectedValues.length > 0
          ? selectedValues.map((option) => {
              return (
                <div className="multi-dropdown-item" key={option.value}>
                  <p className="multi-dropdown-label">{option.label}</p>

                  <FontAwesomeIcon
                    onClick={() => {
                      handleRemoveItem(option);
                    }}
                    icon={faXmark}
                    className="remove-item"
                  />
                </div>
              );
            })
          : ''}
        {handleRemoveAll && (
          <button
            onClick={() => handleRemoveAll()}
            className={`clear-all-button ${selectedValues.length === 0 && 'disabled'}`}
          >
            <FontAwesomeIcon icon={faEraser} />
            <span>Clear filters</span>
          </button>
        )}
      </div>
    </>
  );
}

export default MultiDropdown;

import React, { SetStateAction, useEffect, useState } from 'react';
import { ArticleCandidate } from '../types';
import Button from './Button';
import FancyDropdown from './FancyDropdown';

interface Content {
  identifier: string;
  content: {
    text?: { insert: string }[];
    image?: { id: string };
    src?: { insert: string }[];
  };
}

interface Article {
  articletype: string;
  unity_article_id: string;
  type_code: string | number | boolean;
  data: {
    content: Content[];
  };
}

interface RegenerateModalProps {
  layouts: ArticleCandidate[];
  data: { articles: Article[] };
  onDone: (updatedData: { articles: Article[] }) => void;
}

const MaxCharactersOptions = [
  { value: 8500, label: '8500' },
  { value: 9500, label: '9500' },
  { value: 10500, label: '10500' },
  { value: 0, label: 'No limit' }
];

const RegenerateModal: React.FC<RegenerateModalProps> = ({ layouts, data, onDone }) => {
  const [selectedImages, setSelectedImages] = useState<{ [key: string]: string }>({});
  const [selectedMaxCharacters, setSelectedMaxCharacters] = useState<{
    value: number;
    label: string;
  }>({ value: 9500, label: '9500' });

  useEffect(() => {
    const initialSelectedImages = data.articles.reduce(
      (acc, article) => {
        const firstImage = article.data.content.find((content) => content.identifier === 'image');
        if (firstImage?.content.image?.id) {
          acc[article.unity_article_id] = firstImage.content.image.id;
        }
        return acc;
      },
      {} as { [key: string]: string }
    );

    setSelectedImages(initialSelectedImages);
  }, [data.articles]);

  const handleContentByImage = (content: Content[], selectedImageId: string | undefined) => {
    if (!selectedImageId) return content;

    if (selectedImageId === 'noPicture') {
      return content.filter((obj) => obj.identifier !== 'image');
    }

    const imageIndex = content.findIndex(
      (obj) => obj.identifier === 'image' && obj.content.image?.id === selectedImageId
    );

    if (imageIndex > -1) {
      const selectedImage = content.splice(imageIndex, 1)[0];
      content.unshift(selectedImage);
    }
    return content;
  };

  const handleImageClick = (articleId: string, imageId: string) => {
    setSelectedImages((prevSelected) => ({
      ...prevSelected,
      [articleId]: imageId
    }));
  };

  const handleDone = () => {
    const updatedData = {
      ...data,
      articles: data.articles.map((article) => {
        const selectedImageId = selectedImages[article.unity_article_id];
        return {
          ...article,
          data: {
            ...article.data,
            content: handleContentByImage(article.data.content, selectedImageId)
          }
        };
      }),
      requestedTextLength: selectedMaxCharacters.value,
      thresholdPenalty: Math.min(
        Math.round(layouts.reduce((sum: any, item: any) => sum + item.total_penalty, 0) / 3),
        100
      )
    };

    onDone(updatedData);
  };

  const handleChangeMaxCharacters = (obj: SetStateAction<{ value: number; label: string }>) => {
    setSelectedMaxCharacters(obj);
  };

  return (
    <div className="regenerate-modal-wrapper">
      <div className="max-characters-wrapper">
        <h3>Select maximum characters per page </h3>
        <div className="max-characters-select">
          <FancyDropdown
            value={selectedMaxCharacters.label}
            options={MaxCharactersOptions}
            getValue={(obj: any) => {
              handleChangeMaxCharacters(obj);
            }}
          ></FancyDropdown>
        </div>
      </div>
      <div>
        <h3>Select another image</h3>
        {data.articles.map((article) => {
          const titleObj = article.data.content.find((obj) => obj.identifier === 'title');

          return (
            <div key={article.unity_article_id} className="select-image-content">
              <div className="select-image-title-wrapper">
                <div className="select-image-title">
                  {titleObj?.content?.text?.[0]?.insert || 'Untitled'}
                  {' - '}
                  {article.articletype}
                </div>
              </div>
              <div className="select-image-items">
                {article.data.content
                  .filter((obj) => obj.identifier === 'image')
                  .map((obj) => (
                    <div
                      key={obj.content.image?.id}
                      className={`select-image-item ${
                        selectedImages[article.unity_article_id] === obj.content.image?.id
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() =>
                        handleImageClick(article.unity_article_id, obj.content.image?.id || '')
                      }
                    >
                      {obj.content.src?.[0]?.insert && (
                        <img
                          src={obj.content.src[0].insert}
                          alt={`Image for article ${article.unity_article_id}`}
                          className="select-image-item-image"
                          loading="lazy"
                        />
                      )}
                    </div>
                  ))}
                <div
                  className={`select-image-item ${
                    selectedImages[article.unity_article_id] === 'noPicture' ? 'selected' : ''
                  }`}
                  onClick={() => handleImageClick(article.unity_article_id, 'noPicture')}
                >
                  <div className="no-image-placeholder">No Picture</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="button-wrapper">
        <Button text="Regenerate" onClick={handleDone} />
      </div>
    </div>
  );
};

export default RegenerateModal;

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
type Props = {
  text: string;
};

const CustomTooltip: React.FC<Props> = (props) => {
  const { text } = props;

  return (
    <div className="custom-tooltip">
      <FontAwesomeIcon icon={faCircleExclamation} />
      <span>{text}</span>
    </div>
  );
};

export default CustomTooltip;

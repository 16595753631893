import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  footer
}: {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: JSX.Element;
  footer?: JSX.Element;
}) => {
  return (
    <>
      {isOpen && (
        <div className="modal-wrapper">
          <div className="modal-background" onClick={() => onClose()} />
          <div className="modal-container">
            <div className="modal-header">
              <div className="modal-title">{title}</div>
              <FontAwesomeIcon
                className="modal-close"
                onClick={() => {
                  onClose();
                }}
                icon={faX}
              />
            </div>
            <div className="modal-content">{children}</div>
            <div className="modal-footer">{footer}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

import axios from 'axios';
import JSZip from 'jszip';
import { useState } from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import bronze from '../assets/bronze.png';
import download from '../assets/download.png';
import gold from '../assets/gold.png';
import silver from '../assets/silver.png';
import { ArticleCandidate } from '../types';
import PDFItem from './PDFItem';

type ShowPDFProps = {
  pdfs: ArticleCandidate[];
  getArticle: (a: any) => void;
  loading?: boolean;
  index?: any;
};

const MEDALS_ICONS = [gold, silver, bronze];
const MEDALS = ['gold', 'silver', 'bronze'];

const ShowPDF = ({ pdfs, getArticle, loading = false, index }: ShowPDFProps) => {
  const [checkboxValue, setCheckBoxValue] = useState<string | undefined>(undefined);
  const [showPenalty, setShowPenalty] = useState<boolean>(false);

  const downloadPDFsAsZip = async () => {
    try {
      const zip = new JSZip();

      const promises = pdfs.map(async (item: any, index: number) => {
        const name = `${index}-layout-${MEDALS[index]}.pdf`; // Provide a unique name for each PDF
        const response = await axios.get(item.page_url, { responseType: 'arraybuffer' });
        zip.file(name, response.data);
      });

      await Promise.all(promises);

      zip
        .generateAsync({ type: 'blob' })
        .then((content) => {
          const zipBlob = new Blob([content], { type: 'application/zip' });
          const zipUrl = URL.createObjectURL(zipBlob);

          const link = document.createElement('a');
          link.href = zipUrl;
          link.download = 'articles.zip';
          link.click();

          // Clean up
          URL.revokeObjectURL(zipUrl);
        })
        .catch((e) => console.error(e));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadButton = (url: string) => {
    const arr = url.split('/');
    const name = arr[arr.length - 1];
    const pdfUrl = url;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = name; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url
  ).toString();

  function handleCheckBoxChange(item: any) {
    if (item.printInstructions_url === checkboxValue) {
      setCheckBoxValue(undefined);
      getArticle(null);
    } else {
      getArticle(item);
      setCheckBoxValue(item.printInstructions_url);
    }
  }

  return (
    <>
      {index > 0 && (
        <div className="previous-generations">
          <h3>Generation date: {pdfs[0].generationDate}</h3>
          <hr />
        </div>
      )}
      <div
        className={`download-all ${showPenalty ? 'download-all-expanded' : 'download-all-short'} ${loading && 'disabled'}`}
      >
        {' '}
        Download All
        <img src={download} alt="" onClick={downloadPDFsAsZip} />
      </div>
      <div className="pdf">
        {pdfs.map((item: any, i: number) => {
          return (
            <PDFItem
              key={i}
              item={item}
              i={i}
              handleCheckBoxChange={handleCheckBoxChange}
              checkboxValue={checkboxValue}
              MEDALS_ICONS={MEDALS_ICONS}
              handleDownloadButton={handleDownloadButton}
              download={download}
              showPenalty={showPenalty}
              setShowPenalty={setShowPenalty}
              loading={loading}
            />
          );
        })}
      </div>
    </>
  );
};

export default ShowPDF;

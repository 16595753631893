import { Link } from 'react-router-dom';
import { elementStatusColor } from '../constants';

import {
  faChartPie,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import CustomTooltip from './CustomTooltip';
import Dropdown from './Dropdown';

type Props = any;

const aditionalImageValuesList = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' }
];

const ArticlesPlanningItem: React.FC<Props> = (props) => {
  const {
    listItem,
    handleChangeGraphWidth,
    handleChangeGraphHeight,
    handleChangeAdditionalImage,
    zIndex
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isGraphOpen, setIsGraphOpen] = useState<boolean>(false);
  return (
    <div style={{ zIndex: zIndex }} className="element">
      <div className="page-planing">
        <div className="page-planing-header">
          {isOpen ? (
            <FontAwesomeIcon onClick={() => setIsOpen(!isOpen)} icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon onClick={() => setIsOpen(!isOpen)} icon={faChevronDown} />
          )}
          <div>
            <span className="item-name">
              {listItem.main.folio} - {listItem.main.itemName}
            </span>
          </div>
          <div className="button-generate-wrapper">
            <Link
              to={{
                pathname: '/layouts',
                search: `?item=${encodeURIComponent(JSON.stringify(listItem))}`
              }}
              target="_blank"
              className={`${!listItem.main.itemName || listItem?.data?.length == 0 ? 'disabled' : ''}`}
            >
              <button
                className="btn-generate"
                disabled={!listItem.main.itemName || listItem?.data?.length == 0}
              >
                <FontAwesomeIcon icon={faUpRightFromSquare} />
                <p>Generate</p>
              </button>
            </Link>
          </div>
        </div>
        {listItem?.data?.length && listItem.data.length > 0 ? (
          <div className={`flexTable ${isOpen && 'open-table'}`}>
            <div className="flexTable-header">
              <div className="flexTable-cell">Element status</div>
              <div className="flexTable-cell">Publication status</div>
              <div className="flexTable-cell">Type</div>
              <div className="flexTable-cell slug">Slug</div>
              <div className="flexTable-cell small">Text lenght</div>
              <div className="flexTable-cell small">Actual text length</div>
              <div className="flexTable-cell small">Aditional images</div>
              <div
                className={`flexTable-cell graph-header ${!isGraphOpen && 'closed'}`}
                onClick={() => {
                  setIsGraphOpen(!isGraphOpen);
                }}
              >
                {isGraphOpen ? (
                  <>
                    <FontAwesomeIcon icon={faChartPie} />
                    Graphics in mm
                    <FontAwesomeIcon icon={faChevronRight} />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faChartPie} />
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </>
                )}
              </div>
            </div>
            {listItem?.data?.map((data: any) => {
              return (
                <div className="flexTable-row" key={data.id}>
                  <div className="flexTable-cell ">
                    <div
                      className="elements-status-color"
                      style={{
                        backgroundColor: elementStatusColor[data.elementStatus]
                      }}
                    ></div>
                    <a
                      href={data.deskNetUrl}
                      target="_blank"
                      className="desk-net-redirect"
                      rel="noreferrer"
                    >
                      {data.elementStatus}
                    </a>
                    {!data.unityId ? (
                      <CustomTooltip text="Missing Unity ID for this article"></CustomTooltip>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="flexTable-cell">{data.publicationStatus}</div>
                  <div className="flexTable-cell">{data.type}</div>
                  <div className="flexTable-cell slug ">{data.slug}</div>
                  <div className="flexTable-cell small">{data.textLenght}</div>
                  <div className="flexTable-cell small">{data.scope ? data.scope : '-'}</div>
                  <div className="flexTable-cell small">
                    <Dropdown
                      value={data.additional_image}
                      options={aditionalImageValuesList}
                      getValue={(obj) =>
                        handleChangeAdditionalImage(obj, listItem.main.folio, data.id)
                      }
                    ></Dropdown>
                  </div>
                  <div className={`flexTable-cell graphs ${!isGraphOpen && 'closed'}`}>
                    {data.type != '1sp_Print' && data.type != '1col_Print' && isGraphOpen ? (
                      <>
                        <div className="graphs-input-wrapper">
                          <label className="graph-label">W</label>
                          <input
                            className="graph-input"
                            type="number"
                            value={data.graph.width}
                            onChange={(e) =>
                              handleChangeGraphWidth(e, listItem.main.folio, data.id)
                            }
                          ></input>
                        </div>
                        <div className="graphs-input-wrapper">
                          <label className="graph-label">H</label>
                          <input
                            className="graph-input"
                            type="number"
                            value={data.graph.height}
                            onChange={(e) =>
                              handleChangeGraphHeight(e, listItem.main.folio, data.id)
                            }
                          ></input>
                        </div>
                      </>
                    ) : data.graph.height && data.graph.width ? (
                      <FontAwesomeIcon className="check-graphs-values" icon={faCheck} />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ArticlesPlanningItem;

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { Document, Page } from 'react-pdf';

const PDFItem = (props: {
  item: any;
  i: any;
  handleCheckBoxChange: any;
  checkboxValue: any;
  MEDALS_ICONS: any;
  handleDownloadButton: any;
  download: any;
  showPenalty: boolean;
  setShowPenalty: (value: boolean) => void;
  loading: boolean;
}) => {
  const {
    item,
    i,
    handleCheckBoxChange,
    checkboxValue,
    MEDALS_ICONS,
    handleDownloadButton,
    download,
    showPenalty,
    setShowPenalty,
    loading
  } = props;

  return (
    <Fragment>
      <section className="pdf-icons-section">
        <div className="pdf-checkbox">
          <input
            value={item.printInstructions_url}
            type="checkbox"
            onChange={() => handleCheckBoxChange(item)}
            checked={checkboxValue === item.printInstructions_url}
            disabled={loading}
          />
        </div>
        <div className={`pdf-medal ${loading && 'disabled'}`}>
          <img src={MEDALS_ICONS[i]} alt="" />
        </div>
        <div
          className={`pdf-download ${loading && 'disabled'}`}
          onClick={() => handleDownloadButton(item.page_url)}
        >
          <img src={download} alt="" />
        </div>
      </section>
      <Document file={item.page_url} key={`document_${i}`}>
        <Page key={`page_${1}`} pageNumber={1} />
        {showPenalty ? <Page key={`page_${2}`} pageNumber={2} /> : ''}
        <div className="pdf-show-penalty" onClick={() => setShowPenalty(!showPenalty)}>
          {!showPenalty ? (
            <FontAwesomeIcon icon={faChevronRight} />
          ) : (
            <FontAwesomeIcon icon={faChevronLeft} />
          )}
        </div>
      </Document>
      <hr key={`hr_${i}`} />
    </Fragment>
  );
};

export default PDFItem;

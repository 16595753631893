import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { sendToInDesign } from '../api';
import imageOverlay from '../assets/ai.avif';
import back from '../assets/back.png';
import ArticlesPlanning from '../components/ArticlesPlanning';
import Button from '../components/Button';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import ShowPDF from '../components/ShowPDF';
import config from '../config';
import { ArticleArray, ArticleCandidate } from '../types';

const hyperParametersTypes = [
  { popSize: 50, generation: 180, mut: 0.08, cross: 0.7 }, // default params
  { popSize: 30, generation: 100, mut: 0.08, cross: 0.7 },
  { popSize: 20, generation: 50, mut: 0.06, cross: 0.6 }
];

const Home = () => {
  const [articles, setArticles] = useState<ArticleCandidate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<ArticleArray>({ articles: [] });
  const [selectedArticle, setSelectedArticle] = useState<ArticleCandidate | null>(null);
  console.log(JSON.stringify(file));

  async function handleGenerate(newFile: any) {
    setLoading(true);
    setFile(newFile);
    const id = toast.loading('Generating the page. This can take a while ...');

    try {
      const responses = await Promise.all(
        hyperParametersTypes.map((type) => {
          return axios.post(
            `${config.backend_url}/generate_layout`,
            { ...newFile, hyperParameters: type },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
        })
      );
      const results: any[] | ((prevState: ArticleCandidate[]) => ArticleCandidate[]) = [];
      responses.forEach((response) => results.push(response.data));
      results.sort((a, b) => a.total_penalty - b.total_penalty);
      setArticles(results);
      toast.update(id, {
        render: 'Page generated',
        type: 'success',
        isLoading: false,
        autoClose: 3000
      });
      setLoading(false);
    } catch (error) {
      toast.update(id, {
        render: 'Something went wrong',
        type: 'error',
        isLoading: false,
        autoClose: 3000
      });
      setLoading(false);
      setFile(() => ({ articles: [] }));
      console.log('error', error);
    }
  }

  async function handleClick() {
    setLoading(true);
    const id = toast.loading('Generating the page. This can take a while ...');

    // ** only for testing **
    // setTimeout(() => {
    //   setArticles(layoutResponse);
    //   toast.update(id, {
    //     render: 'Page generated',
    //     type: 'success',
    //     isLoading: false,
    //     closeOnClick: true,
    //     draggable: true,
    //     closeButton: true
    //   });
    //   setLoading(false);
    // }, 2000);

    try {
      const responses = await Promise.all(
        hyperParametersTypes.map((type) => {
          return axios.post(
            `${config.backend_url}/generate_layout`,
            { ...file, hyperParameters: type },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
        })
      );
      const results: any[] | ((prevState: ArticleCandidate[]) => ArticleCandidate[]) = [];
      responses.forEach((response) => results.push(response.data));
      results.sort((a, b) => a.total_penalty - b.total_penalty);
      setArticles(results);
      toast.update(id, {
        render: 'Page generated',
        type: 'success',
        isLoading: false,
        autoClose: 3000
      });
      setLoading(false);
    } catch (error) {
      toast.update(id, {
        render: 'Something went wrong',
        type: 'error',
        isLoading: false,
        autoClose: 3000
      });
      setLoading(false);
      setFile(() => ({ articles: [] }));
      console.log('error', error);
    }
  }

  const hasArticles = articles?.length > 0;

  function handleSendToInDesign() {
    if (selectedArticle?.printInstructions_url) {
      const id = toast.loading('Sending page instructions to InDesign!');
      sendToInDesign(selectedArticle?.printInstructions_url)
        .then(() => {
          toast.update(id, {
            render: 'Page sent successfully to InDesign',
            type: 'success',
            isLoading: false,
            autoClose: 3000
          });
        })
        .catch((error) => {
          toast.update(id, {
            render: 'Failed to send page to InDesign',
            type: 'error',
            isLoading: false,
            autoClose: 3000
          });
          console.error('Error sending page to InDesign:', error);
        });
    }
  }

  return (
    <>
      <Layout />
      <div className={`main-container ${hasArticles ? 'main-container--fitContent' : ''}`}>
        <img className="image-overlay" src={imageOverlay} alt="image-overlay" />

        {!loading && !hasArticles && (
          <>
            <ArticlesPlanning handleGenerate={handleGenerate} />
          </>
        )}

        {loading ? (
          <Loader />
        ) : (
          hasArticles && (
            <>
              <section className="main-container__action-bar">
                <img
                  className="back-icon"
                  src={back}
                  alt="back"
                  onClick={() => {
                    setFile({ articles: [] });
                    setArticles([]);
                    setSelectedArticle(null);
                  }}
                />
                <div className="main-container__action-bar__section">
                  <Button text="Regenerate" onClick={handleClick} />{' '}
                  <Button
                    text="Send to InDesign"
                    onClick={() => handleSendToInDesign()}
                    disabled={!selectedArticle}
                  />
                </div>
              </section>
              <ShowPDF pdfs={articles} getArticle={setSelectedArticle} />
            </>
          )
        )}
      </div>

      {/* <footer>© 2023 Tamedia. All Rights Reserved</footer>
      <img src={logo} className="App-logo" alt="logo" /> */}
    </>
  );
};

export default Home;

import axios from 'axios';
import config from '../config';

export async function getXMLInputfiles(platform: string, date: string) {
  return axios.post(`${config.backend_url}/get-inputfiles`, {
    platform: platform,
    date: date
  });
}

export async function getUnityArticle(unityArticleID: string) {
  return axios.post(`${config.backend_url}/get-unity-article`, {
    unity_article_id: unityArticleID
  });
}

export async function getDeskNetElements(platformId: string, date: string) {
  return axios.post(`${config.backend_url}/get-desk-net-elements`, {
    platform_id: platformId,
    start_date: date,
    end_date: date
  });
}

export async function sendToInDesign(printInstructions_url: string) {
  return axios.post(`${config.backend_url}/send-to-indesign`, {
    printInstructions_url
  });
}

import { useOktaAuth } from '@okta/okta-react';
import oktaLogo from '../assets/okta.svg';
import tamediaLogo from '../assets/tamedia.png';
import PJS from 'particles.js-es';
import particlesOptions from '../utils/particlesConf.json';

import { useEffect } from 'react';
const Login = () => {
  const { oktaAuth } = useOktaAuth();

  const login = async () => oktaAuth.signInWithRedirect({ originalUri: '/' });

  useEffect(() => {
    PJS.init('particles-js', particlesOptions);
  }, []);
  return (
    <div className="frame-layout__wrapper">
      <div className="frame-layout__particles-container">
        <div id="particles-js" className="frame-layout__particles" />
      </div>
      <section className="login-page__title">
        <img src={tamediaLogo} alt="tamediaLogo" />
        <h1>Print Automation</h1>
      </section>

      <section className="login-page__okta">
        <button className="btn btn-login" onClick={login}>
          Login with Okta
          <img src={oktaLogo} alt="oktaLogo" />
        </button>
      </section>
    </div>
  );
};

export default Login;

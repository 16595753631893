const CLIENT_ID = process.env.CLIENT_ID;
const BASENAME = process.env.FRONTEND_URL || '';
const ISSUER = process.env.ISSUER || 'https://login.tx.group/oauth2/default';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/openid/callback`;
const BACKEND_URL = process.env.BACKEND_URL || 'https://autolayout-api.ness-dev.tamedia.ch';
const FEED_URL = process.env.FEED_URL || 'https://staging.unitycms.io';
const NODE_ENV = process.env.NODE_ENV;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email']
  },
  backend_url: BACKEND_URL,
  feed_url: FEED_URL,
  node_env: NODE_ENV
};
